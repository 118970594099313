







import Vue from "vue";
import { DateTime } from "luxon";
import { Component, Prop } from "vue-property-decorator";

import { Condition, ConditionType } from "@/core/models";

@Component
export default class ConditionDisplay extends Vue {
  @Prop({ default: () => {} }) condition!: Condition;

  get isDueDate() {
    return this.condition.type === ConditionType.DueDate;
  }
  get value() {
    let val = this.condition.value;
    const type = this.condition.type;
    if (type === ConditionType.DueDate) {
      const d = DateTime.fromISO(val);
      const now = DateTime.fromMillis(Date.now());
      if (d < now) return this.$t("goals.condmsg.overdue").toString();
      const rel = d.setLocale(this.$i18n.locale).toRelative();
      return `${this.$t("goals.condmsg.duein")} ${rel}`;
    }

    if (typeof val === "string" && val.includes(";")) val = val.split(";");

    const msgstr = `goals.condmsg.${type}`;
    switch (type) {
      case ConditionType.SessionScore:
        return this.$t(msgstr, { n: val });
      case ConditionType.FillerWords:
        return this.$t(msgstr, { n: val });
      case ConditionType.SpeechClarity:
        return this.$t(msgstr, { n: val });
      case ConditionType.OverallScore:
        return this.$t(msgstr, { n: val });
      case ConditionType.Repetition:
        return this.$t(msgstr, { n: val });
      case ConditionType.SpeechSpeed:
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Sentiment:
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Pitch:
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Volume:
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Pauses:
        return this.$t(msgstr, { n1: val[0], n2: val[1] });

      default:
        return "";
    }
  }
}

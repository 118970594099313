
























































// TODO: Trans
import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import Pages from "@/core/utils/Pages";
import PagesArrows from "@/components/common/PagesArrows.vue";
import MetaSingle from "./subcomponents/MetaSingle.vue";
import { InformationTooltip } from "@/components/common";
import NumberSelect from "@/components/common/NumberSelect.vue";
import { MetaAnalysis, MetaSettings } from "@/core/models/sessions";

@Component({
  components: {
    InformationTooltip,
    NumberSelect,
    MetaSingle,
    PagesArrows,
  },
})
export default class ProgressCard extends Vue {
  @Getter("sessions/loading") loading!: boolean;
  @Getter("sessions/meta") meta!: MetaAnalysis[];
  @Getter("sessions/metaLoading") metaLoading!: boolean;
  @Getter("sessions/metaSettings") settings!: MetaSettings;
  @Action("displaySnackbar") displaySnackbar!: Function;
  @Action("sessions/updateSettings") updateSettings!: Function;

  // Settings panel
  numOfSessions = 5;
  settingsDialog = false;
  attrs = ["pitch", "speech_speed"];
  allAttrs = [
    { text: "Voice Pitch", value: "pitch" },
    { text: "Voice Volume", value: "volume" },
    { text: "Pauses per Minute", value: "pauses" },
    { text: "Speech Speed", value: "speech_speed" },
    { text: "Session Sentiment", value: "sentiment" },
    // { text: "Session Score", value: "session_score" },
  ];
  saveSettings() {
    if (this.metaLoading) return;
    if (this.attrs.length === 0) {
      const str = this.$t("snack.meta.attrs").toString();
      this.displaySnackbar(str);
      return;
    }
    const data = {
      attributes: this.attrs,
      value: this.numOfSessions,
    };
    this.updateSettings(data);
    this.settingsDialog = false;
  }

  pages = Pages.Empty();
  @Watch("meta", { immediate: true })
  metaUpdated() {
    if (!this.meta) this.pages = Pages.Empty();
    else this.pages = new Pages<MetaAnalysis>(1, this.meta);
  }

  @Watch("metaLoading", { immediate: true })
  metaSettingsLoaded() {
    if (this.metaLoading) return;
    const { Attributes, NumOfSessions } = this.settings;
    this.attrs = Attributes;
    this.numOfSessions = NumOfSessions;
  }
}

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px"},model:{value:(_vm.dialogProp),callback:function ($$v) {_vm.dialogProp=$$v},expression:"dialogProp"}},[_c(VCard,{staticClass:"d-flex recorder-card",attrs:{"elevation":"0","tile":""}},[_c(VCardTitle,{staticStyle:{"width":"100%"}},[_c('div',[_vm._v(_vm._s(_vm.name))]),_c(VSpacer),_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialogProp = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,{staticClass:"d-flex"},[_c('ImageSlider',{attrs:{"images":_vm.images,"max-width":"1000px","max-width-vertical":"1000px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("dashboardPage.groupsCard.groups"))+" "),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"text":"","link":"","small":"","to":"/groups","elevation":"0","color":"accentText"}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("dashboardPage.groupsCard.viewAll")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1),(!_vm.loading)?_c(VCardText,{staticStyle:{"height":"300px","overflow":"auto"}},[(_vm.groups.length > 0)?_c(VList,_vm._l((_vm.groups),function(group,i){return _c('div',{key:i},[_c(VListItem,{on:{"click":function($event){return _vm.goToGroup(group.ID)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"d-flex align-center font-weight-bold"},[_vm._v(" "+_vm._s(group.Name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$t("dashboardPage.groupsCard.created"))+" "+_vm._s(_vm._f("format")(group.CreatedAt))+" ")]),_c(VListItemSubtitle,[_c('span',[_c('b',[_vm._v(_vm._s(group.Users.length))]),_vm._v(" "+_vm._s(_vm.$t("dashboardPage.groupsCard.users"))+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("·")]),_c('span',[_c('b',[_vm._v(_vm._s(group.Videos.length))]),_vm._v(" "+_vm._s(_vm.$t("dashboardPage.groupsCard.videos"))+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("·")]),_c('span',[_c('b',[_vm._v(_vm._s(group.Posts.length))]),_vm._v(" "+_vm._s(_vm.$t("dashboardPage.groupsCard.posts"))+" ")])])],1),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),(i !== _vm.groups.length - 1)?_c(VDivider):_vm._e()],1)}),0):_c('div',[_vm._v(_vm._s(_vm.$t("dashboardPage.groupsCard.notGroupMember")))])],1):_c(VCardText,[_vm._v(_vm._s(_vm.$t("dashboardPage.groupsCard.groupsLoading")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
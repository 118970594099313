import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VListItem,{attrs:{"outlined":""}},[_c(VOverlay,{staticClass:"d-flex align-center justify-center",attrs:{"absolute":"","value":_vm.removing && _vm.removedGoalID == _vm.goal.ID}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"mr-2 font-weight-bold main--text"},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.complete", { n: _vm.goal.Target })))])]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.goal.CreatedAt)))])])],1),_c(VListItemAction,{staticClass:"mx-0"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasConditions)?_c(VBtn,_vm._g({staticClass:"mr-2 white--text primary",attrs:{"icon":""},on:{"click":function($event){_vm.showConditions = !_vm.showConditions}}},on),[_c(VIcon,[_vm._v("mdi-bullseye")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.conds")))])])],1),_c(VListItemAction,{staticClass:"mx-0"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasUsers)?_c(VBtn,_vm._g({staticClass:"mr-2 white--text primary",attrs:{"icon":""},on:{"click":function($event){_vm.showUsers = !_vm.showUsers}}},on),[_c(VIcon,[_vm._v("mdi-account")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.assignedu")))])])],1),_c(VListItemAction,{staticClass:"mx-0"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasUsers)?_c(VBtn,_vm._g({staticClass:"white--text primary",attrs:{"icon":""},on:{"click":_vm.removeGoal}},on),[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.remove")))])])],1)],1),_c(VDialog,{attrs:{"max-width":"400px"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.confirm")))])]),_c(VCardText,[_c('span',[_vm._v(_vm._s(_vm.$t("goals.confirmmsg")))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"prezpRed"},on:{"click":function($event){_vm.confirmDialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":function () {
              _vm.confirm = true;
              _vm.removeGoal();
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.confirm")))])])],1)],1)],1),(_vm.hasConditions)?_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConditions),expression:"showConditions"}]},[_c('div',{staticClass:"pa-4"},_vm._l((_vm.goal.Conditions),function(cond,i){return _c('ConditionDisplay',{key:i,attrs:{"condition":cond}})}),1)])]):_vm._e(),(_vm.hasUsers)?_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUsers),expression:"showUsers"}]},[_c('div',{staticClass:"pa-4"},_vm._l((_vm.goal.AssingedUsers),function(user,i){return _c('div',{key:i,class:{ 'mb-4': i !== _vm.usersCount - 1 }},[_c('div',[_vm._v(_vm._s(user.Username)+" ("+_vm._s(user.Email)+")")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VProgressLinear,_vm._g({staticClass:"mt-2",attrs:{"rounded":"","height":"10px","background-opacity":"0.2","value":(user.Progress / _vm.goal.Target) * 100}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(user.Progress)+"/"+_vm._s(_vm.goal.Target))])])],1)}),0)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
























import Vue from "vue";
import { ImageSlider } from "@/components/common";
import { Presentation } from "@/core/models/";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component({ components: { ImageSlider } })
export default class ExpandSlidesDialog extends Vue {
  @Prop({ default: () => null }) presentation!: Presentation;
  @PropSync("dialog", { type: Boolean, default: () => false })
  dialogProp!: boolean;

  get name() {
    return this.presentation.Name || this.presentation.File.Name;
  }
  get images() {
    return this.presentation.Slides.map(s => s.Uri);
  }
}

import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"my-2"},[_c(VListItemAvatar,{attrs:{"size":"60"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VProgressCircular,_vm._g({attrs:{"value":_vm.goal.ProgressValue,"size":"60","width":"5","color":"primary"}},on),[_c('span',{staticClass:"font-weight-bold main--text"},[_vm._v(" "+_vm._s(Math.floor(_vm.goal.ProgressValue))+"% ")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.text))])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("goals.complete", { n: _vm.goal.Target }))+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.assigner))]),_vm._l((_vm.goal.Conditions),function(cond,i){return _c('ConditionDisplay',{key:i,attrs:{"condition":cond}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Group } from "@/core/models";
import { Component } from "vue-property-decorator";
import { InformationTooltip } from "@/components/common";

@Component({ components: { InformationTooltip } })
export default class GroupsCard extends Vue {
  @Getter("groups/loadingGroups") loading!: boolean;
  @Getter("groups/getGroups") groups!: Group[];

  goToGroup(id: number) {
    this.$router.push("/groups/" + id);
  }
}

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_c(VBtn,_vm._g({attrs:{"disabled":_vm.addGoalLoading,"loading":_vm.addGoalLoading,"color":"primary","elevation":"0","left":""},on:{"click":_vm.toggleDialog}},on),[_c(VIcon,[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("goals.add")))])],1)],1)]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.add")))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-2"},[_c(VContainer,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.addGoal($event)}}},[_c('div',{attrs:{"data-tour":"goals-target"}},[_c('NumberSelect',{attrs:{"number":_vm.targetSessions},on:{"update:number":function($event){_vm.targetSessions=$event}}})],1),_c('div',{attrs:{"data-tour":"goals-conditions"}},[_c('ConditionsSelect',{attrs:{"conditions":_vm.conditions},on:{"update:conditions":function($event){_vm.conditions=$event}}})],1),_c('div',{attrs:{"data-tour":"goals-users"}},[_c('UsersSelect',{attrs:{"users":_vm.users},on:{"update":function (val) { return (_vm.selectedUsers = val); }}})],1),_c(VCheckbox,{attrs:{"label":_vm.$t('goals.assigny')},model:{value:(_vm.assignOneself),callback:function ($$v) {_vm.assignOneself=$$v},expression:"assignOneself"}}),_c('div',{attrs:{"data-tour":"goals-confirm"}},[_c(VBtn,{attrs:{"block":"","elevation":"0","color":"primary","disabled":!_vm.canSubmit},on:{"click":_vm.addGoal}},[_c('span',[_vm._v(_vm._s(_vm.$t("goals.submit")))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import GoalsCard from "./GoalsCard.vue";
import GroupsCard from "./GroupsCard.vue";
import VideosCard from "./VideosCard.vue";
import NewsCard from "./NewsCard.vue";
import ProgressCard from "./ProgressCard.vue";
import PresentationsCard from "./PresentationsCard.vue";
import HowToDash from "./HowToDash.vue";

export {
  GoalsCard,
  GroupsCard,
  VideosCard,
  NewsCard,
  ProgressCard,
  PresentationsCard,
  HowToDash,
};








































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Presentation } from "@/core/models/";
import { Component, Watch } from "vue-property-decorator";

import Pages from "@/core/utils/Pages";
import PagesArrows from "@/components/common/PagesArrows.vue";
import { UploadFileButton, ImageSlider } from "@/components/common";
import SinglePresentation from "./subcomponents/SinglePresentation.vue";
import InformationTooltip from "@/components/common/InformationTooltip.vue";

@Component({
  components: {
    InformationTooltip,
    SinglePresentation,
    UploadFileButton,
    ImageSlider,
    PagesArrows,
  },
})
export default class PresentationsCard extends Vue {
  @Getter("isDarkMode") dark!: boolean;
  @Getter("presentations/loading") loading!: boolean;
  @Getter("presentations/getUploadLoading") uploadLoading!: boolean;
  @Action("presentations/uploadPresentation") uploadPresentation: any;
  @Getter("presentations/getPresentations") presentations!: Presentation[];

  selected: Presentation | null = null;
  get selectedId() {
    if (!this.selected) return null;
    return this.selected.ID;
  }
  get images() {
    if (!this.selected) return [];
    if (!this.selected.Slides.length) return [];
    return this.selected.Slides.map(s => s.Uri);
  }
  select(pres: Presentation) {
    this.selected = pres;
  }
  remove(pres: Presentation) {
    this.selected = this.presentations.filter(x => x.ID !== pres.ID)[0];
  }

  uploadFile(file: any) {
    if (this.uploadLoading) return;
    this.uploadPresentation(file);
  }

  limit: number = 6;
  pages: Pages<Presentation> = Pages.Empty();
  @Watch("presentations", { immediate: true })
  presentationsLoaded() {
    if (!this.loading) {
      if (!this.selected && this.presentations.length > 0)
        this.select(this.presentations[0]);
      this.pages = new Pages(this.limit, this.presentations);
    }
  }
}

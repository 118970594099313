import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,{staticClass:"d-flex"},[_c('div',{staticClass:"headline font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t("dashboardPage.presentationsCard.presentations")))])]),_c(VSpacer)],1),_c(VCardText,[_c('div',[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12","md":_vm.selected ? '6' : '12'}},[(_vm.presentations.length > 0)?_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"py-0",staticStyle:{"overflow":"visible !important"},attrs:{"data-tour":"pres-list"}},_vm._l((_vm.pages.CurrentPage),function(pres){return _c('SinglePresentation',{key:pres.ID,attrs:{"presentation":pres,"is-selected":_vm.selectedId === pres.ID},on:{"select":function($event){return _vm.select(pres)},"remove":function($event){return _vm.remove(pres)}}})}),1),_c('PagesArrows',{attrs:{"pages":_vm.pages}})],1):_c('div',{staticClass:"text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$t("pres.none"))+" ")]),_c('div',{staticStyle:{"flex":"1"}}),_c('div',{attrs:{"data-tour":"pres-btn"}},[_c('UploadFileButton',{staticClass:"mt-4",attrs:{"accept":"application/pdf","loading":_vm.loading || _vm.uploadLoading,"block":false,"raised":true,"icon":true,"color":"primary"},on:{"submit":_vm.uploadFile}})],1)]),(_vm.selected)?_c(VCol,{staticClass:"d-flex",staticStyle:{"justify-content":"center"},attrs:{"data-tour":"pres-slides","md":"6","cols":"12"}},[(_vm.images.length)?_c('ImageSlider',{attrs:{"images":_vm.images}}):_c('div',[_vm._v(" "+_vm._s(_vm.$t("pres.no_slides"))+" ")])],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,[_c('span',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("dashboardPage.sessionsCard.sessions"))+" ")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){_vm.settingsDialog = !_vm.settingsDialog}}},[_c(VIcon,[_vm._v("mdi-cog")])],1),_c('InformationTooltip',{attrs:{"message":"Score Over Time"}})],1),(_vm.metaLoading)?_c(VCardText,[_vm._v("Refreshing Meta Analysis...")]):_vm._e(),(_vm.meta && _vm.meta.length !== 0 && !_vm.metaLoading)?_c(VCardText,{staticClass:"main--text"},[(!_vm.metaLoading)?_c('MetaSingle',{attrs:{"meta":_vm.pages.CurrentPage[0]}}):_vm._e(),_c('PagesArrows',{attrs:{"pages":_vm.pages}})],1):_c(VCardText,[_vm._v(" Could not get meta analysis. There are sessions being analyzed. ")])],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.settingsDialog),callback:function ($$v) {_vm.settingsDialog=$$v},expression:"settingsDialog"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.saveSettings($event)}}},[_c(VCard,[_c(VCardTitle,[_vm._v("Meta Analysis Settings")]),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"text-center"},[_vm._v("Select number of sessions to comapre")]),_c('NumberSelect',{attrs:{"number":_vm.numOfSessions},on:{"update:number":function($event){_vm.numOfSessions=$event}}}),_c(VSelect,{attrs:{"label":"Attributes","items":_vm.allAttrs,"clearable":"","multiple":"","chips":""},model:{value:(_vm.attrs),callback:function ($$v) {_vm.attrs=$$v},expression:"attrs"}}),_c(VDivider)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"primary","type":"submit"}},[_c('span',[_vm._v("Submit and Refresh")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import i18n from "../plugins/i18n";

type Step = {
  title?: string;
  body?: string;
  element?: string;
  xPos?: "left" | "right" | "center";
  yPos?: "top" | "bottom";
  open?: boolean;
  negativeOffset?: boolean;
  noNext?: boolean;
};

export class Tour {
  current = -1;
  steps: Step[];
  constructor(steps: Step[]) {
    this.steps = steps;
  }

  private clear() {
    document.querySelector(".tour-wrapper")?.remove();
    document.querySelector(".tour-hint")?.remove();
    document.querySelector(".tour-popup")?.remove();
  }

  private getWrapper() {
    let wrapper = document.querySelector(".tour-wrapper");
    if (!wrapper) {
      wrapper = document.createElement("div");
      wrapper.classList.add("tour-wrapper");
      document.documentElement.append(wrapper);
    }
    return wrapper;
  }

  private showHint(
    { title, body, xPos, yPos, negativeOffset, noNext, open }: Step,
    el: Element,
  ) {
    const hint = document.createElement("div");
    hint.classList.add("tour-hint");
    if (negativeOffset) hint.classList.add("inverse");

    const popup = document.createElement("div");
    popup.classList.add("tour-popup");
    popup.insertAdjacentHTML(
      "beforeend",
      `<div class="title">${title}</div>
      <div class="body">${body}</div>
      <div class="actions">
        <div class="button skip">${i18n.t("tour.step.skip")}</div>
        <div class="margin"></div>
        <div class="button exit">${i18n.t("tour.step.exit")}</div>
        <div class="spacer"></div>
        <div class="button next">${
          noNext ? i18n.t("tour.step.close") : i18n.t("tour.step.next")
        }
        </div>
      </div>`,
    );
    const skip = popup.querySelector(".actions .skip") as HTMLDivElement;
    if (skip) skip.onclick = () => this.finish();
    const exit = popup.querySelector(".actions .exit") as HTMLDivElement;
    if (exit) exit.onclick = () => this.exit();
    const next = popup.querySelector(".actions .next") as HTMLDivElement;
    if (next) next.onclick = () => (noNext ? this.clear() : this.next());

    // position popup
    el.scrollIntoView({ block: "center" });

    // open fn
    const openPopup = () => {
      if (noNext) {
        // position
        popup.style.left = "64px";
        popup.style.bottom = "64px";

        // append + remove
        popup.classList.add("shown");
        document.documentElement.append(popup);
        hint.remove();
        return;
      }

      const rect = el.getBoundingClientRect();

      // along x
      if (xPos === "center")
        popup.style.left = rect.x + rect.width - 200 + "px";
      else if (xPos === "right") popup.style.left = rect.x + rect.width + "px";
      else
        popup.style.right =
          window.innerWidth - (rect.x + rect.width - 24) + "px";

      // along y
      if (yPos === "top")
        popup.style.bottom = window.innerHeight - rect.y + "px";
      else popup.style.top = rect.y + "px";

      // append + remove
      popup.classList.add("shown");
      document.documentElement.append(popup);
      hint.remove();
    };

    // trigger open
    if (open) openPopup();
    else hint.onclick = openPopup;

    // append elements
    el.append(hint);
    (el as any).style.position = "relative";
    return { hint, popup };
  }

  // todo translate
  private showDialog({ title, body, noNext }: Step, offset?: number) {
    // helpers
    const isLast = this.current === this.steps.length - 1;

    // add dialog
    const dialog = document.createElement("div");
    dialog.classList.add("tour-dialog");
    if (offset) {
      dialog.classList.add("highlight");
      dialog.style.top = offset + "px";
    }

    dialog.insertAdjacentHTML(
      "beforeend",
      `<div class="header">
          <div class="step">${this.current + 1} ${i18n.t("tour.step.of")} 
          ${this.steps.length}</div>
          <div class="skip">${i18n.t("tour.step.skip")}</div>
          <div class="exit">${i18n.t("tour.step.exit")}</div>
        </div>
    
        <div class="content">
          <div class="title">${title}</div>
          <div class="body">${body}</div>
        </div>

        <div class="actions">
          <div class="button next">${
            isLast
              ? i18n.t("tour.step.finish")
              : noNext
              ? "Ok"
              : i18n.t("tour.step.next")
          }</div>
        </div>`,
    );

    // bind clicks
    const next = dialog.querySelector(".actions .next") as HTMLDivElement;
    if (next)
      next.onclick = () =>
        isLast ? this.finish() : noNext ? this.clear() : this.next();
    const exit = dialog.querySelector(".header .exit") as HTMLDivElement;
    if (exit) exit.onclick = () => this.exit();
    const skip = dialog.querySelector(".header .skip") as HTMLDivElement;
    if (skip) skip.onclick = () => this.finish();

    this.getWrapper().append(dialog);
    return dialog;
  }

  private executeStep() {
    this.clear();
    const step = this.steps[this.current];
    const el = document.querySelector(`[data-tour='${step.element}']`);
    if (!el) this.showDialog(step);
    else this.showHint(step, el);
  }

  start() {
    this.current = -1;
    this.next();
  }
  jump(i: number) {
    this.current = i - 1;
    this.next();
  }
  next() {
    if (this.current + 1 >= this.steps.length) this.exit();
    this.current++;
    this.executeStep();
  }
  finish() {
    window.dispatchEvent(new Event("tour-next"));
    this.exit();
  }
  exit() {
    this.current = -1;
    this.clear();
  }
}

export const createHomeTour = () => {
  return new Tour([
    {
      title: i18n.t("tour.home.0.title").toString(),
      body: i18n.t("tour.home.0.body").toString(),
    },
    {
      title: i18n.t("tour.home.1.title").toString(),
      body: i18n.t("tour.home.1.body").toString(),
      element: "seminars",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.home.2.title").toString(),
      body: i18n.t("tour.home.2.body").toString(),
      element: "invitations",
      xPos: "left",
      yPos: "top",
    },
    {
      title: i18n.t("tour.home.3.title").toString(),
      body: i18n.t("tour.home.3.body").toString(),
    },
  ]);
};

export const createDashTour = () => {
  return new Tour([
    {
      title: i18n.t("tour.dash.0.title").toString(),
      body: i18n.t("tour.dash.0.body").toString(),
    },
    {
      title: i18n.t("tour.dash.1.title").toString(),
      body: i18n.t("tour.dash.1.body").toString(),
      element: "pres",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.dash.2.title").toString(),
      body: i18n.t("tour.dash.2.body").toString(),
      element: "pres-btn",
      negativeOffset: true,
      xPos: "right",
      yPos: "top",
    },
    {
      title: i18n.t("tour.dash.3.title").toString(),
      body: i18n.t("tour.dash.3.body").toString(),
      element: "pres-list",
      xPos: "right",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.dash.4.title").toString(),
      body: i18n.t("tour.dash.4.body").toString(),
      element: "pres-slides",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.dash.5.title").toString(),
      body: i18n.t("tour.dash.5.body").toString(),
      element: "goals",
      xPos: "right",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.dash.6.title").toString(),
      body: i18n.t("tour.dash.6.body").toString(),
      element: "groups",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.dash.7.title").toString(),
      body: i18n.t("tour.dash.7.body").toString(),
      element: "videos",
      xPos: "left",
      yPos: "top",
    },
    {
      title: i18n.t("tour.dash.8.title").toString(),
      body: i18n.t("tour.dash.8.body").toString(),
      element: "news",
      xPos: "left",
      yPos: "top",
    },
    {
      title: i18n.t("tour.dash.9.title").toString(),
      body: i18n.t("tour.dash.9.body").toString(),
    },
  ]);
};

export const createGoalsTour = () => {
  return new Tour([
    {
      title: i18n.t("tour.goals.0.title").toString(),
      body: i18n.t("tour.goals.0.body").toString(),
    },
    {
      title: i18n.t("tour.goals.1.title").toString(),
      body: i18n.t("tour.goals.1.body").toString(),
      element: "goals-btn",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
      noNext: true,
    },
    {
      title: i18n.t("tour.goals.2.title").toString(),
      body: i18n.t("tour.goals.2.body").toString(),
    },
    {
      title: i18n.t("tour.goals.3.title").toString(),
      body: i18n.t("tour.goals.3.body").toString(),
      element: "goals-target",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.goals.4.title").toString(),
      body: i18n.t("tour.goals.4.body").toString(),
      element: "goals-conditions",
      xPos: "left",
      yPos: "top",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.goals.5.title").toString(),
      body: i18n.t("tour.goals.5.body").toString(),
      element: "goals-users",
      xPos: "left",
      yPos: "top",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.goals.6.title").toString(),
      body: i18n.t("tour.goals.6.body").toString(),
      element: "goals-confirm",
      xPos: "left",
      yPos: "top",
      noNext: true,
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.goals.7.title").toString(),
      body: i18n.t("tour.goals.7.body").toString(),
    },
  ]);
};

export const createRecorderTour = () => {
  return new Tour([
    {
      title: i18n.t("tour.recorder.0.title").toString(),
      body: i18n.t("tour.recorder.0.body").toString(),
    },
    {
      title: i18n.t("tour.recorder.1.title").toString(),
      body: i18n.t("tour.recorder.1.body").toString(),
      element: "list",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.recorder.2.title").toString(),
      body: i18n.t("tour.recorder.2.body").toString(),
      element: "recorder",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.recorder.3.title").toString(),
      body: i18n.t("tour.recorder.3.body").toString(),
      element: "pres-select",
      xPos: "left",
      yPos: "bottom",
      noNext: true, // 3
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.4.title").toString(),
      body: i18n.t("tour.recorder.4.body").toString(),
      element: "slides",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.5.title").toString(),
      body: i18n.t("tour.recorder.5.body").toString(),
      element: "lang-select",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.6.title").toString(),
      body: i18n.t("tour.recorder.6.body").toString(),
      element: "device-select",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.7.title").toString(),
      body: i18n.t("tour.recorder.7.body").toString(),
      element: "mute",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.8.title").toString(),
      body: i18n.t("tour.recorder.8.body").toString(),
      element: "volume-tester",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.9.title").toString(),
      body: i18n.t("tour.recorder.9.body").toString(),
      element: "record-btn",
      xPos: "left",
      yPos: "bottom",
      noNext: true, // 9
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.10.title").toString(),
      body: i18n.t("tour.recorder.10.body").toString(),
      noNext: true, // 10
    },
    {
      title: i18n.t("tour.recorder.11.title").toString(),
      body: i18n.t("tour.recorder.11.body").toString(),
      noNext: true, // 11
    },
    {
      title: i18n.t("tour.recorder.12.title").toString(),
      body: i18n.t("tour.recorder.12.body").toString(),
      noNext: true, // 12
    },
    {
      title: i18n.t("tour.recorder.13.title").toString(),
      body: i18n.t("tour.recorder.13.body").toString(),
      element: "video-preview",
      xPos: "left",
      yPos: "bottom",
    },
    {
      title: i18n.t("tour.recorder.14.title").toString(),
      body: i18n.t("tour.recorder.14.body").toString(),
      element: "actions",
      xPos: "left",
      yPos: "bottom",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.recorder.15.title").toString(),
      body: i18n.t("tour.recorder.15.body").toString(),
      noNext: true, // 15
    },
    {
      title: i18n.t("tour.recorder.16.title").toString(),
      body: i18n.t("tour.recorder.16.body").toString(),
      noNext: true, // 16
    },
    {
      title: i18n.t("tour.recorder.17.title").toString(),
      body: i18n.t("tour.recorder.17.body").toString(),
      noNext: true, // 17
      element: "create-sess",
    },
    {
      title: i18n.t("tour.recorder.18.title").toString(),
      body: i18n.t("tour.recorder.18.body").toString(),
    },
  ]);
};

export const createSessionsTour = () => {
  return new Tour([
    {
      title: i18n.t("tour.sessions.0.title").toString(),
      body: i18n.t("tour.sessions.0.body").toString(),
    },
    {
      title: i18n.t("tour.sessions.1.title").toString(),
      body: i18n.t("tour.sessions.1.body").toString(),
      element: "list",
      xPos: "right",
    },
    {
      title: i18n.t("tour.sessions.2.title").toString(),
      body: i18n.t("tour.sessions.2.body").toString(),
      element: "filters",
      xPos: "right",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.sessions.3.title").toString(),
      body: i18n.t("tour.sessions.3.body").toString(),
      element: "shared",
      xPos: "right",
      negativeOffset: true,
    },
    {
      title: i18n.t("tour.sessions.4.title").toString(),
      body: i18n.t("tour.sessions.4.body").toString(),
      noNext: true, // 4
    },
    {
      title: i18n.t("tour.sessions.5.title").toString(),
      body: i18n.t("tour.sessions.5.body").toString(),
    },
    {
      title: i18n.t("tour.sessions.6.title").toString(),
      body: i18n.t("tour.sessions.6.body").toString(),
      element: "scores",
    },
    {
      title: i18n.t("tour.sessions.7.title").toString(),
      body: i18n.t("tour.sessions.7.body").toString(),
      element: "slides",
    },
    {
      title: i18n.t("tour.sessions.8.title").toString(),
      body: i18n.t("tour.sessions.8.body").toString(),
      element: "wpm",
    },
    {
      title: i18n.t("tour.sessions.9.title").toString(),
      body: i18n.t("tour.sessions.9.body").toString(),
      element: "pauses",
    },
    {
      title: i18n.t("tour.sessions.10.title").toString(),
      body: i18n.t("tour.sessions.10.body").toString(),
      element: "pitch",
    },
    {
      title: i18n.t("tour.sessions.11.title").toString(),
      body: i18n.t("tour.sessions.11.body").toString(),
      element: "sentences",
    },
    {
      title: i18n.t("tour.sessions.12.title").toString(),
      body: i18n.t("tour.sessions.12.body").toString(),
      element: "lookup",
    },
    {
      title: i18n.t("tour.sessions.13.title").toString(),
      body: i18n.t("tour.sessions.13.body").toString(),
      element: "ratio",
    },
    {
      title: i18n.t("tour.sessions.14.title").toString(),
      body: i18n.t("tour.sessions.14.body").toString(),
      element: "keywords",
    },
    {
      title: i18n.t("tour.sessions.15.title").toString(),
      body: i18n.t("tour.sessions.15.body").toString(),
      element: "movement",
    },
    {
      title: i18n.t("tour.sessions.16.title").toString(),
      body: i18n.t("tour.sessions.16.body").toString(),
      element: "controls",
      negativeOffset: true,
      yPos: "top",
    },
    {
      title: i18n.t("tour.sessions.17.title").toString(),
      body: i18n.t("tour.sessions.17.body").toString(),
      element: "zoom",
      negativeOffset: true,
      xPos: "right",
      yPos: "top",
    },
    {
      title: i18n.t("tour.sessions.18.title").toString(),
      body: i18n.t("tour.sessions.18.body").toString(),
      element: "points",
      negativeOffset: true,
      xPos: "right",
      yPos: "top",
    },
    {
      title: i18n.t("tour.sessions.19.title").toString(),
      body: i18n.t("tour.sessions.19.body").toString(),
      element: "benchmark",
      negativeOffset: true,
      xPos: "right",
      yPos: "top",
    },
    {
      title: i18n.t("tour.sessions.20.title").toString(),
      body: i18n.t("tour.sessions.20.body").toString(),
      noNext: true, // 20
    },
    {
      title: i18n.t("tour.sessions.21.title").toString(),
      body: i18n.t("tour.sessions.21.body").toString(),
    },
    {
      title: i18n.t("tour.sessions.22.title").toString(),
      body: i18n.t("tour.sessions.22.body").toString(),
      element: "limit",
    },
    {
      title: i18n.t("tour.sessions.23.title").toString(),
      body: i18n.t("tour.sessions.23.body").toString(),
    },
  ]);
};


















































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Goal } from "@/core/models";
import ConditionDisplay from "./ConditionDisplay.vue";

@Component({ components: { ConditionDisplay } })
export default class CreatedGoal extends Vue {
  @Prop({ default: () => [] }) goal!: Goal;
  @Getter("goals/removeGoalLoading") removing!: boolean;
  @Getter("goals/removedGoalID") removedGoalID!: boolean;
  @Action("goals/removeGoal") removeGoalAction!: Function;

  showUsers = false;
  showConditions = false;
  get hasConditions() {
    return this.goal.Conditions.length !== 0;
  }
  get conditionsCount() {
    return this.goal.Conditions.length;
  }
  get hasUsers() {
    return this.goal.AssingedUsers.length !== 0;
  }
  get usersCount() {
    return this.goal.AssingedUsers.length;
  }

  confirmDialog = false;
  confirm = false;
  removeGoal() {
    if (this.removing) return;
    if (!this.confirm) {
      this.confirmDialog = true;
      return;
    }
    this.removeGoalAction(this.goal.ID);
    this.confirmDialog = false;
    this.confirm = false;
  }
}

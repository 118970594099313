
























import Vue from "vue";
import { Getter } from "vuex-class";
import { AssignedGoal } from "@/core/models";
import { Component } from "vue-property-decorator";
import { InformationTooltip } from "@/components/common";
import { AssignedGoalItem } from "@/views/goals/components";

@Component({
  components: {
    AssignedGoalItem,
    InformationTooltip,
  },
})
export default class GoalsCard extends Vue {
  @Getter("goals/loading") loading!: boolean;
  @Getter("goals/getAssignedGoals") goals!: AssignedGoal[];
}

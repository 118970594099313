




































import Vue from "vue";
import { DateTime } from "luxon";
import { Getter } from "vuex-class";
import { ChartOptions, ChartData } from "chart.js";
import { Component, Prop } from "vue-property-decorator";

import { MetaAnalysis } from "@/core/models/sessions";
import { BarChart } from "@/components/charts/base-charts";

@Component({ components: { BarChart } })
export default class MetaSingle extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => null }) meta!: MetaAnalysis;

  get name() {
    const attr = this.meta.attributeType;
    const str = this.$t(`meta.names.${attr}`).toString();
    return str || "";
  }
  get unit() {
    const attr = this.meta.attributeType;
    const str = this.$t(`meta.units.${attr}`).toString();
    return str || "";
  }
  get deviation() {
    const num = this.meta.deviation;
    return Math.round(num * 10) / 10;
  }
  get formerAvg() {
    const num = this.meta.avgFormerSessions;
    return Math.round(num * 10) / 10;
  }
  get latterAvg() {
    const num = this.meta.avgLatterSessions;
    return Math.round(num * 10) / 10;
  }
  get percents() {
    const { avgFormerSessions: former, avgLatterSessions: latter } = this.meta;
    const max = Math.max(former, latter);
    const other = Math.min(former, latter);
    const percent = Math.round((other / max) * 100);

    if (former > latter) return [100, percent];
    return [percent, 100];
  }

  get chartData() {
    const {
      metaAnalysisFormerSessions: former,
      metaAnalysisLatterSessions: latter,
    } = this.meta;

    const sessions = [...former, ...latter].sort((a, b) => +a.date - +b.date);
    const labels = sessions.map(s =>
      DateTime.fromJSDate(s.date).toLocaleString(DateTime.DATE_MED as any),
    );
    const data = sessions.map(s => s.average);

    const mapper = (s: { average: number }) => Math.round(s.average * 10) / 10;
    const set1Data = former.map(mapper);
    const set2Data = latter.map(mapper);

    const set1Color = this.theme.primary + "";
    const set2Color = this.theme.accent + "";
    const colors = [
      ...Array.from({ length: set2Data.length }, () => set2Color),
      ...Array.from({ length: set1Data.length }, () => set1Color),
    ];

    const dataset: ChartData = {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
        },
      ],
    };
    return dataset;
  }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false, labels: { usePointStyle: true } },
    scales: { yAxes: [{ ticks: { beginAtZero: true, min: 0 } }] },
  };
}
